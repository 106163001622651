import revive_payload_client_mXR3CwFQab from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_bpjpw3azchcvkhppn6e2rsjjty/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_dvtb5Km3ZV from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_bpjpw3azchcvkhppn6e2rsjjty/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xXPu5BZXJ2 from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_bpjpw3azchcvkhppn6e2rsjjty/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_M4xsHiaGjc from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@5.4.14_@types+node@22.13.5_less@4.2.2_terser@5.39_rrh5emaxg5bkx3uok3enpztd7u/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_mgr70eSTvZ from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_bpjpw3azchcvkhppn6e2rsjjty/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_yUW0Uhr6yD from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_bpjpw3azchcvkhppn6e2rsjjty/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ifIqwoml9v from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_bpjpw3azchcvkhppn6e2rsjjty/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_4errnV9eo8 from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_bpjpw3azchcvkhppn6e2rsjjty/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_KfbI4lR8CT from "/builds/WeArePublic/wap-app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/WeArePublic/wap-app/.nuxt/components.plugin.mjs";
import prefetch_client_HrGS4Kij3z from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_bpjpw3azchcvkhppn6e2rsjjty/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/builds/WeArePublic/wap-app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_TFccjdl78v from "/builds/WeArePublic/wap-app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_vite@5.4.14_@types+node_lig3d2kwcrc3ssdabssjfstp2m/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_wNbhRecexF from "/builds/WeArePublic/wap-app/node_modules/.pnpm/@digitalnatives+graphql-client@4.1.1_graphql@16.10.0_magicast@0.3.5_nuxt@3.13.2_@parcel+watch_me5dkdjyntndglyjcbg4wnm3v4/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_TXlY2b5e2s from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt-bugsnag@8.3.1_@bugsnag+core@8.2.0_magicast@0.3.5/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import nuxt_plugin_iME3KC628D from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import dates_byd27p6IKa from "/builds/WeArePublic/wap-app/plugins/dates.js";
import globalData_ThkhP3KCAA from "/builds/WeArePublic/wap-app/plugins/globalData.ts";
import gtm_cXsUZobmSF from "/builds/WeArePublic/wap-app/plugins/gtm.ts";
import in_view_CFLen2qDdE from "/builds/WeArePublic/wap-app/plugins/in-view.js";
import v_clipboard_oVMg8deQnQ from "/builds/WeArePublic/wap-app/plugins/v-clipboard.js";
import v_dragscroll_JehTUROx3N from "/builds/WeArePublic/wap-app/plugins/v-dragscroll.js";
import vee_validate_KcKlKmvCrJ from "/builds/WeArePublic/wap-app/plugins/vee-validate.ts";
export default [
  revive_payload_client_mXR3CwFQab,
  unhead_dvtb5Km3ZV,
  router_xXPu5BZXJ2,
  _0_siteConfig_M4xsHiaGjc,
  payload_client_mgr70eSTvZ,
  navigation_repaint_client_yUW0Uhr6yD,
  check_outdated_build_client_ifIqwoml9v,
  chunk_reload_client_4errnV9eo8,
  plugin_vue3_KfbI4lR8CT,
  components_plugin_KR1HBZs4kY,
  prefetch_client_HrGS4Kij3z,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_TFccjdl78v,
  plugin_wNbhRecexF,
  plugin_TXlY2b5e2s,
  nuxt_plugin_iME3KC628D,
  dates_byd27p6IKa,
  globalData_ThkhP3KCAA,
  gtm_cXsUZobmSF,
  in_view_CFLen2qDdE,
  v_clipboard_oVMg8deQnQ,
  v_dragscroll_JehTUROx3N,
  vee_validate_KcKlKmvCrJ
]