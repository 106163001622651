import { default as _91_46_46_46slug_93mNkHK0rhfNMeta } from "/builds/WeArePublic/wap-app/pages/[...slug].vue?macro=true";
import { default as _91slug_93gTDvvZg7ULMeta } from "/builds/WeArePublic/wap-app/pages/collectie/[slug].vue?macro=true";
import { default as indexD87zJFjkI4Meta } from "/builds/WeArePublic/wap-app/pages/index.vue?macro=true";
import { default as loginvlYZ84ff1ZMeta } from "/builds/WeArePublic/wap-app/pages/login.vue?macro=true";
import { default as logout1udwo4m9GsMeta } from "/builds/WeArePublic/wap-app/pages/logout.vue?macro=true";
import { default as abonnemente9p5cNMZGDMeta } from "/builds/WeArePublic/wap-app/pages/profiel/abonnement.vue?macro=true";
import { default as beveiligingYlnvUgPgycMeta } from "/builds/WeArePublic/wap-app/pages/profiel/beveiliging.vue?macro=true";
import { default as bezochte_45programmask7feSlqpLqMeta } from "/builds/WeArePublic/wap-app/pages/profiel/bezochte-programmas.vue?macro=true";
import { default as gegevensvZbowd3c1uMeta } from "/builds/WeArePublic/wap-app/pages/profiel/gegevens.vue?macro=true";
import { default as notificatiesQlMGg3NSMKMeta } from "/builds/WeArePublic/wap-app/pages/profiel/notificaties.vue?macro=true";
import { default as overzichtOsHPasZQjoMeta } from "/builds/WeArePublic/wap-app/pages/profiel/overzicht.vue?macro=true";
import { default as reserveringenYEV0NHyyNHMeta } from "/builds/WeArePublic/wap-app/pages/profiel/reserveringen.vue?macro=true";
import { default as voorkeurenBjwmTzdbyOMeta } from "/builds/WeArePublic/wap-app/pages/profiel/voorkeuren.vue?macro=true";
import { default as _91id_93VnIeEvxY9UMeta } from "/builds/WeArePublic/wap-app/pages/programma/[slug]/[id].vue?macro=true";
import { default as scan58LhKoxkJ5Meta } from "/builds/WeArePublic/wap-app/pages/scan.vue?macro=true";
import { default as zoekenSOPIGCfpwTMeta } from "/builds/WeArePublic/wap-app/pages/zoeken.vue?macro=true";
import { default as component_45stubBN8LWw82kUMeta } from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_bpjpw3azchcvkhppn6e2rsjjty/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubBN8LWw82kU } from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_bpjpw3azchcvkhppn6e2rsjjty/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/WeArePublic/wap-app/pages/[...slug].vue")
  },
  {
    name: "collectie-slug",
    path: "/collectie/:slug()",
    component: () => import("/builds/WeArePublic/wap-app/pages/collectie/[slug].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/WeArePublic/wap-app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginvlYZ84ff1ZMeta || {},
    component: () => import("/builds/WeArePublic/wap-app/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logout1udwo4m9GsMeta || {},
    component: () => import("/builds/WeArePublic/wap-app/pages/logout.vue")
  },
  {
    name: "profiel-abonnement",
    path: "/profiel/abonnement",
    meta: abonnemente9p5cNMZGDMeta || {},
    component: () => import("/builds/WeArePublic/wap-app/pages/profiel/abonnement.vue")
  },
  {
    name: "profiel-beveiliging",
    path: "/profiel/beveiliging",
    meta: beveiligingYlnvUgPgycMeta || {},
    component: () => import("/builds/WeArePublic/wap-app/pages/profiel/beveiliging.vue")
  },
  {
    name: "profiel-bezochte-programmas",
    path: "/profiel/bezochte-programmas",
    meta: bezochte_45programmask7feSlqpLqMeta || {},
    component: () => import("/builds/WeArePublic/wap-app/pages/profiel/bezochte-programmas.vue")
  },
  {
    name: "profiel-gegevens",
    path: "/profiel/gegevens",
    meta: gegevensvZbowd3c1uMeta || {},
    component: () => import("/builds/WeArePublic/wap-app/pages/profiel/gegevens.vue")
  },
  {
    name: "profiel-notificaties",
    path: "/profiel/notificaties",
    meta: notificatiesQlMGg3NSMKMeta || {},
    component: () => import("/builds/WeArePublic/wap-app/pages/profiel/notificaties.vue")
  },
  {
    name: "profiel-overzicht",
    path: "/profiel/overzicht",
    meta: overzichtOsHPasZQjoMeta || {},
    component: () => import("/builds/WeArePublic/wap-app/pages/profiel/overzicht.vue")
  },
  {
    name: "profiel-reserveringen",
    path: "/profiel/reserveringen",
    meta: reserveringenYEV0NHyyNHMeta || {},
    component: () => import("/builds/WeArePublic/wap-app/pages/profiel/reserveringen.vue")
  },
  {
    name: "profiel-voorkeuren",
    path: "/profiel/voorkeuren",
    meta: voorkeurenBjwmTzdbyOMeta || {},
    component: () => import("/builds/WeArePublic/wap-app/pages/profiel/voorkeuren.vue")
  },
  {
    name: "programma-slug-id",
    path: "/programma/:slug()/:id()",
    component: () => import("/builds/WeArePublic/wap-app/pages/programma/[slug]/[id].vue")
  },
  {
    name: "scan",
    path: "/scan",
    component: () => import("/builds/WeArePublic/wap-app/pages/scan.vue")
  },
  {
    name: "zoeken",
    path: "/zoeken",
    meta: zoekenSOPIGCfpwTMeta || {},
    component: () => import("/builds/WeArePublic/wap-app/pages/zoeken.vue")
  },
  {
    name: component_45stubBN8LWw82kUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubBN8LWw82kU
  },
  {
    name: component_45stubBN8LWw82kUMeta?.name,
    path: "/pages-sitemap.xml",
    component: component_45stubBN8LWw82kU
  },
  {
    name: component_45stubBN8LWw82kUMeta?.name,
    path: "/collections-sitemap.xml",
    component: component_45stubBN8LWw82kU
  },
  {
    name: component_45stubBN8LWw82kUMeta?.name,
    path: "/events-sitemap.xml",
    component: component_45stubBN8LWw82kU
  }
]