import validate from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_bpjpw3azchcvkhppn6e2rsjjty/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45event_45date_45global from "/builds/WeArePublic/wap-app/middleware/01.event-date.global.ts";
import manifest_45route_45rule from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_bpjpw3azchcvkhppn6e2rsjjty/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45event_45date_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/builds/WeArePublic/wap-app/middleware/auth.ts"),
  "mollie-transaction-redirect": () => import("/builds/WeArePublic/wap-app/middleware/mollieTransactionRedirect.js")
}